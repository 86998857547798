<template>
  <div class="login-con">
    <div class="login-title-wrap">
      <span class="login-title">
        蜗牛音效移动管理后台
      </span>
    </div>
    <van-form @submit="onSubmit" style="width:100vw;">
      <van-field
        v-model="username"
        name="username"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <van-field name="rememberAccount" label="记住密码">
        <template #input>
          <van-checkbox v-model="rememberAccount" shape="square" @change="rmaChange" />
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import api from "@/api";
import { Toast } from 'vant';
export default {
  data() {
    return {
      username: '',
      password: '',
      rememberAccount: false,
    };
  },
  methods: {
    onSubmit(values) {

      api.user.login(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            this.$store.commit('login',r)
            Toast.success('登录成功');
            if(this.rememberAccount){
              let b64 = btoa( JSON.stringify({u:values.username,p:values.password}) )
              localStorage.setItem('rma',b64)
            }else{
              localStorage.removeItem('rma')
            }
            this.$router.push({name:'Home'})
          }else{
              console.log(res)
              Toast.fail('登录失败');
          }
      }).catch(err=>{
          Toast.fail(err);
      })
    },
    rmaChange(v){
      console.log('v',v)
      if(!v){
        if(localStorage.getItem('rma')){
          localStorage.removeItem('rma')
          this.username = ''
          this.password = ''
          Toast.success('清理记录的账号成功');  
        }
      }
    }
  },
  created(){
    try {
      let rma = localStorage.getItem('rma');
      if(rma){
        let d = JSON.parse( atob(rma) );
        this.username = d.u
        this.password = d.p
        this.rememberAccount = true
      }
    } catch (error) {
      
    }
  }
};
</script>

<style lang="scss" scoped>
.login-con{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #eeeeee;
  .login-title-wrap{
    padding: 15px 0;
    .login-title{
      font-size: 26px;
      color: #666;
    }

  }
}
</style>
